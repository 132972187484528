import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import AuthContext from "../../../services/AuthContext";
import {DetailsList, SelectionMode} from "@fluentui/react";
import MDButton from "../../../components/MDButton";
import IconButton from "@mui/material/IconButton";
import {Delete, DeleteForever, Edit, FileDownload} from "@mui/icons-material";
import DeleteForeverMember from "../../../Panels/Member/DeleteForeverMember";
import EditMember from "../../../Panels/Member/EditMember";
import DeleteMembership from "../../../Panels/Member/DeleteMembership";
import MDTypography from "../../../components/MDTypography";
import currency from "currency.js";
import {CSVDownloader} from "react-papaparse";

function Archive() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [members, setMembers] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [deleteForever, setDeleteForever] = useState(false);
    const [deleteMembership, setDeleteMembership] = useState(false);
    const [editMember, setEditMember] = useState(false);
    const [summary, setSummary] = useState('');

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/members/archive`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then((data) => {
                    setMembers(data);
                    const saldo = data.reduce((c,v) => c.add(currency(v.balance < 0 ? v.balance : 0)), currency(0, {
                        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
                    }));
                    setSummary(<MDTypography textAlign={'right'} fontWeight={'light'} variant={'h4'}>Zaległości: {saldo.format()}
                        <CSVDownloader data={data.filter((i) => i.balance < 0)} filename={`zaleglosci`} bom={true} config={{delimiter: ';'}}>
                            <IconButton variant={'text'}><FileDownload /></IconButton>
                        </CSVDownloader>
                    </MDTypography>);
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
        return () => abortController.abort();
    }, [user, params.id, refresh]);


    function _makeButtons(item) {
        return (<>
                <IconButton color={'success'} onClick={() => setEditMember(item)}><Edit /></IconButton>
                <IconButton color={'error'} onClick={() => setDeleteMembership(item)}><Delete /></IconButton>
                <IconButton color={'error'} onClick={() => setDeleteForever(item.id)}><DeleteForever /></IconButton>
            </>
        );
    }

    const _memberLink = (item) => {
        return (<Link to={`/facility/${params.id}/department/${item.department_id}/member/${item.id}`}><MDButton color={'secondary'} fullWidth>{item.surname} {item.name}</MDButton></Link>);
    }

    const _departmentLink = (item) => {
        return (<Link to={`/facility/${params.id}/department/${item.department_id}`}><MDButton color={'light'} fullWidth>{item.department}</MDButton></Link>);
    }

    const columns = [
        {
            key: 'fullname',
            name: 'Imię i Nazwisko',
            ariaLabel: 'Imię i Nazwisko',
            fieldName: 'fullname',
            minWidth: 120,
            isResizable: true,
            onRender: _memberLink
        },
        {
            key: 'department',
            name: 'Wydział',
            ariaLabel: 'Wydział',
            fieldName: 'department',
            minWidth: 180,
            isResizable: true,
            onRender: _departmentLink
        },
        {
            key: 'year',
            name: 'Rocznik',
            ariaLabel: 'Rocznik',
            fieldName: 'year',
            minWidth: 70,
            isResizable: false,
        },
        {
            key: 'diet',
            name: 'Dieta',
            ariaLabel: 'Dieta',
            fieldName: 'diet',
            minWidth: 140,
            isResizable: true,
        },
        {
            key: 'active_from',
            name: 'Aktywny Od',
            ariaLabel: 'Od godziny',
            fieldName: 'active_from',
            minWidth: 80,
            isResizable: true,
        },
        {
            key: 'active_to',
            name: 'Aktywny Do',
            ariaLabel: 'Aktywny Do',
            fieldName: 'active_to',
            minWidth: 80,
            isResizable: true,
        },
        {
            key: 'balance',
            name: 'Saldo',
            ariaLabel: 'Saldo',
            fieldName: 'balance',
            minWidth: 80,
            isResizable: true,
            onRender: (item) => (<MDTypography color={item.balance < 0 ? 'error' : 'secondary'}>{item.balance} zł</MDTypography>)
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 120,
            onRender: _makeButtons
        }
    ];

    return (
        <div>
            {summary}
            <DetailsList items={members} columns={columns} selectionMode={SelectionMode.none} />
            <EditMember data={editMember} dismiss={() => setEditMember(false)}
                        onSuccess={() => setRefresh(refresh + 1)}/>
            <DeleteMembership data={deleteMembership} dismiss={() => setDeleteMembership(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <DeleteForeverMember data={deleteForever} dismiss={() => setDeleteForever(false)} onSuccess={() => setRefresh(refresh + 1)} />
        </div>
    );
}

export default Archive;