import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../../services/AuthContext";
import {AccountTree, Clear, Edit} from "@mui/icons-material";
import {Button} from "@mui/material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import IconButton from "@mui/material/IconButton";
import {useParams} from "react-router-dom";
import GenerateMassPayments from "../../../Panels/Facility/GenerateMassPayments";

function MassPayments() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [accounts, setAccounts] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/facility/${params.id}/mass_payments`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setCanModify(data.canModify);
                    setAccounts(data.accounts)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, refresh, params.id]);

    const columns = [
        {
            key: 'member',
            name: 'Dziecko',
            ariaLabel: 'Dziecko',
            fieldName: 'member',
            minWidth: 200,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: 'department',
            name: 'Oddział',
            ariaLabel: 'Oddział',
            fieldName: 'department',
            minWidth: 140,
            isResizable: true,
        }, {
            key: 'personal_account',
            name: 'Rachunek Indywidualny',
            ariaLabel: 'Rachunek Indywidualny',
            fieldName: 'personal_account',
            minWidth: 250,
            isResizable: true,
        }
    ];

    return (
        <div>
            {canModify && <Button onClick={() => setGenerate(true)}><AccountTree/> Generuj Rachunki Indywidualne</Button>}
            <GenerateMassPayments isOpen={generate} dismiss={() => setGenerate(false)}
                                  onSuccess={() => setRefresh(refresh + 1)}/>
            <DetailsList items={accounts} columns={columns} selectionMode={SelectionMode.none}/>
        </div>
    );
}

export default MassPayments;