import * as React from "react";
import MDButton from "../components/MDButton";
import {Mail} from "@mui/icons-material";

function GroupMail(props) {
    let emails = props.people.reduce((prev, curr,) => {
        if (curr !== undefined && curr !== null) {
            const joined = curr.split(',').filter((item) => {
                return (item !== 'NULL' && item !== '');
            });
            return [...prev, ...joined];
        }
        return prev;
    }, []);
    emails = emails.filter((value, index, self) => self.indexOf(value) === index).join(';');

    return (<a href={'mailto:?bcc=' + emails}><MDButton color={"info"} variant={'text'}><Mail sx={{marginRight: '4px'}}/> Mail Grupowy</MDButton></a>);
}

export default GroupMail;