import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";

function EditDepartment(props) {
    const title = 'Edytuj Wydział';
    const description = 'Tutaj możesz edytować wydział swojej placówki.';

    const [user] = useContext(AuthContext);
    const [name, setName] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [sorting, setSorting] = useState(0);
    const [location, setLocation] = useState(1);

    function _send() {
        const formData = {
            name: name,
            year: year,
            sorting: sorting,
            location: location
        };
        fetch(`/api/facility/${props.data.facility_id}/department/${props.data.id}`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setName(props.data.name);
        setYear(props.data.year);
        setSorting(props.data.sorting);
        setLocation(props.data.location);
    }, [props.data]);

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}
                     actionLabel='Edytuj'>
            <MDInput
                onChange={(e) => setName(e.target.value)}
                value={name}
                label='Nazwa'
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setYear(e.target.value)}
                value={year}
                label='Rocznik'
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setSorting(e.target.value)}
                value={sorting}
                label='Kolejność'
                type={'number'}
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setLocation(e.target.value)}
                value={location}
                label='Szatnia'
                type={'number'}
                fullWidth
                sx={{marginBottom: 2}}
            />
        </StyledModal>
    );
}

export default EditDepartment;