import React, {useContext, useState, useEffect} from "react";
// core components
import Facility from "components/Kindercamp/Facility.js";
import AuthContext from "../../services/AuthContext";
import Title from "../../Component/Title";
import GroupMail from "../../Component/GroupMail";
import MDTypography from "../../components/MDTypography";
import {Boy, Girl, Group} from "@mui/icons-material";
import DefaultInfoCard from "../../Component/Card/DefaultInfoCard";
import {Link} from "react-router-dom";
import {Card} from "@mui/material";
import MDBox from "../../components/MDBox";

function Dashboard() {
    const [user] = useContext(AuthContext);
    const emails = user.facilities.map(i => i.emails);

    const makeMemberUrl = (item) => {
        return `/member/${item.id}`;
    }

    const gender = (item) => {
        if (item.gender === 'M') {
            return {icon: <Boy/>, color: 'info'}
        } else {
            return {icon: <Girl/>, color: 'primary'}
        }
    }

    return (
        <>
            <Title title={'Kokpit'}/>
            {user.members.length > 0 && <MDTypography fontWeight={'light'} variant={'h4'}>Dzieci</MDTypography>}
            {user.members.map(item => (
                <MDBox sx={{margin: '20px 0', paddingTop: '20px'}} key={item.id}>
                    <Link to={makeMemberUrl(item)} key={item.id}>
                        <DefaultInfoCard url={makeMemberUrl(item)} {...gender(item)}
                                         title={`${item.name} ${item.surname}`} description={item.facility}
                                         key={item.id}/>
                    </Link>
                </MDBox>
            ))}
            {emails.length > 0 && <GroupMail people={emails}/>}
            {user.facilities.length > 0 && <MDTypography fontWeight={'light'} variant={'h4'}>Placówki</MDTypography>}
            {user.facilities.map(item => (
                <Facility id={item.id}
                          key={item.id}
                          title={item.name}
                          address={`${item.postal_code} ${item.city} ${item.address}`}
                          departments={item.departments}
                          members={item.active_members}
                          attendance={item.attendance}
                          meals={item.meals}
                          emails={item.emails}
                          unpaid={item.unpaid}
                          color={'success'}
                />
            ))}

        </>
    );
}

export default Dashboard;
