import React, {useContext, useEffect, useState} from "react";
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import {useOutletContext, useParams} from "react-router-dom";
import MDTypography from "../../../../components/MDTypography";
import {DetailsList, SelectionMode} from "@fluentui/react";
import ActionButton from "../../../../StyledComponents/ActionButton";
import {Add, Delete, Edit, Phonelink} from "@mui/icons-material";
import AuthContext from "../../../../services/AuthContext";
import AddAllowed from "../../../../Panels/Member/AddAllowed";
import EditAllowed from "../../../../Panels/Member/EditAllowed";

function MemberData() {
    const {data, setSummary, refreshMember} = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [addAllowed, setAddAllowed] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);
    const [mealBlockade, setMealBlockade] = useState(false);
    const allowed = data?.allowed !== undefined ? data.allowed : [];
    const meals = data?.meals !== undefined ? data.meals : [];

    useEffect(() => {
        setSummary(null);
    }, [])
    function _delete(id) {
        fetch(`/api/member/allowed/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                refreshMember();
            })
            .catch(err => { console.log(err) })
    }

    function _addMeal(facilityMeal) {
        const formData = {
            facility_meal_id: facilityMeal,
        };
        fetch(`/api/member/${params.member}/meal`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                refreshMember();
            })
            .catch(err => { console.log(err) })
    }

    function _deleteMeal(id) {
        fetch(`/api/member/${params.member}/meal/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                refreshMember();
            })
            .catch(err => { console.log(err) })
    }

    function _makeButtons(item) {
        return (<div>
            <ActionButton color='success' icon={<Edit />} onClick={() => setEditAllowed(item)} />
            <ActionButton color='error' icon={<Delete />} onDoubleClick={() => _delete(item.id)} />
        </div>);
    }

    function _mealButton(item) {
        if (item.blocked) {
            return (<MDButton fullWidth color='error' disabled>{item.name}</MDButton>);
        }
        return item.id ?
            (<MDButton fullWidth color='success' onDoubleClick={() => _deleteMeal(item.id)}>{item.name}</MDButton>) :
            (<MDButton fullWidth color='secondary' onClick={() => _addMeal(item.facility_meal_id)}>{item.name}</MDButton>);
    }

    const columnsMeals = [
        {
            key: 'meals',
            name: 'Posiłek',
            ariaLabel: 'Posiłek',
            fieldName: 'meals',
            minWidth: 200,
            onRender: _mealButton
        }
    ];

    const columnsAllowed = [
        {
            key: 'fullname',
            name: 'Imię i Nazwisko',
            ariaLabel: 'Imię i Nazwisko',
            fieldName: 'fullname',
            minWidth: 150,
            isResizable: true,
            onRender: item => item.active == 1 ? <><Phonelink /> {item.fullname}</> : item.fullname
        },
        {
            key: 'document',
            name: 'Dokument',
            ariaLabel: 'Dokument',
            fieldName: 'document',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'kinship',
            name: 'Pokrewieństwo',
            ariaLabel: 'Pokrewieństwo',
            fieldName: 'kinship',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'contact',
            name: 'Kontakt',
            ariaLabel: 'Kontakt',
            fieldName: 'contact',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'valid',
            name: 'Czasowe do',
            ariaLabel: 'Czasowe do',
            fieldName: 'valid',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: _makeButtons
        }
    ];
    return (<MDBox display={'flex'} justifyContent={'space-between'}>
        <MDBox>
            <MDTypography variant={'h5'} fontWeight={'light'}>Dane</MDTypography>
            <pre>{JSON.stringify(data?.member, null, 4)}</pre>
        </MDBox>
        <MDBox>
            <MDTypography variant={'h5'} fontWeight={'light'}>Posiłki</MDTypography>
            <DetailsList selectionMode={SelectionMode.none} items={meals} columns={columnsMeals} />
        </MDBox>
        <MDBox>
            <MDTypography variant={'h5'} fontWeight={'light'}>Upoważnienia Odbioru</MDTypography>
            <MDButton color={'info'} variant={'text'} onClick={() => setAddAllowed(params.member)} ><Add /> Dodaj Upoważnienie</MDButton>
            <DetailsList selectionMode={SelectionMode.none} items={allowed} columns={columnsAllowed} />
        </MDBox>
        <AddAllowed data={addAllowed} dismiss={() => setAddAllowed(false)} onSuccess={refreshMember} />
        <EditAllowed data={editAllowed} dismiss={() => setEditAllowed(false)} onSuccess={refreshMember} />
    </MDBox>);
}

export default MemberData;