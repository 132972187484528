import React from "react";
import {Grid} from "@mui/material";
import Title from "../../Component/Title";
import Subnavigation from "../../Component/Subnavigation";
import {Outlet} from "react-router-dom";

function Member() {
    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Subnavigation items={[
            {to: 'active', name: 'Aktywni', color: 'info'},
            {to: 'archive', name: 'Archiwum', color: 'secondary'}
        ]} />
    </Grid>);

    return (<>
            <Title title={'Uczestnicy'} subtitle={subtitle}/>
            <Outlet />
        </>
    )
}
export default Member;