import React, {useContext, useEffect, useState} from "react";
// core components
import {Link, useParams} from 'react-router-dom';
import AuthContext from "../../services/AuthContext";
import {Button, Grid} from "@mui/material";
import Title from "../../Component/Title";
import {Add, ExpandMore, Group, Upload} from "@mui/icons-material";
import MDTypography from "../../components/MDTypography";
import AddDepartment from "../../Panels/Department/AddDepartment";
import DefaultInfoCard from "../../Component/Card/DefaultInfoCard";
import ImportFacility from "../../Panels/Department/ImportFacility";
import MDBox from "../../components/MDBox";
import AddAbsence from "../../Panels/Member/AddAbsence";

function FacilityDashboard() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [departments, setDepartments] = useState([]);
    const [addDepartment, setAddDepartment] = useState(false);
    const [addAbsence, setAddAbsence] = useState(false);
    const [importFacility, setImportFacility] = useState(false);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
            const abortController = new AbortController();
            async function fetchData() {
                await fetch(`/api/facility/${params.id}/departments`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        setDepartments(data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }

            fetchData();
            return () => abortController.abort();
        }
    ,  [user, params.id, refresh]);

    const makeUrl = (item) => {
        return `/facility/${item.facility_id}/department/${item.id}`;
    }
    const makeLink = (item) => {
        return (<MDTypography
            component="span"
            variant="button"
            fontWeight='light'><Link to={makeUrl(item)} title="Więcej">
            <ExpandMore />
            Więcej
        </Link></MDTypography>);
    }

  return (
    <>
        <Title title={'Oddziały'} />
        <MDBox>
            <Button onClick={() => setAddDepartment(params.id)}><Add /> Dodaj Oddział</Button>
            <Button onClick={() => setImportFacility(params.id)}><Upload /> Importuj dane</Button>
            <Button onClick={() => setAddAbsence(true)}><Add /> Dodaj Nieobecność</Button>
        </MDBox>
        <Grid container spacing={3}>
        {departments.map(item => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={item.id}>
                <DefaultInfoCard url={makeUrl(item)} icon={<Group />} title={item.name} color={'warning'} description={`${item.members} dzieci`} value={makeLink(item)} key={item.id}/>
            </Grid>))}
        </Grid>
        <AddAbsence data={addAbsence} dismiss={() => setAddAbsence(false)}
                       onSuccess={() => setRefresh(refresh + 1)}/>
        <AddDepartment data={addDepartment} dismiss={() => setAddDepartment(false)}
                   onSuccess={() => setRefresh(refresh + 1)}/>
        <ImportFacility data={importFacility} dismiss={() => setImportFacility(false)}
                   onSuccess={() => setRefresh(refresh + 1)}/>
    </>
    );
}
export default FacilityDashboard;