import React from "react";
import {Close} from "@mui/icons-material";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";


export default function StyledModal({
                                        children,
                                        send,
                                        dismiss,
                                        title,
                                        description,
                                        isOpen = false,
                                        actionLabel = 'Dodaj',
                                        actionColor = 'success',
                                        fullWidth = false,
                                        maxWidth = 'sm',
                                        additionalActions = ''
                                    }) {
    const closeCss = {
        position: 'absolute',
        right: 8,
        top: 8
    };

    return (<Dialog open={isOpen} onClose={dismiss} fullWidth={fullWidth} maxWidth={maxWidth}>
        <IconButton variant={'text'} onClick={dismiss} sx={closeCss}><Close/></IconButton>
        <DialogTitle variant={'h5'} fontWeight={'light'}>
            {title}
        </DialogTitle>
        {description !== undefined && <MDTypography variant={'p'} fontSize={'small'} fontWeight={'regular'}
                                                    sx={{padding: '0 16px'}}>{description}</MDTypography>}
        <DialogContent>

            {children}
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
            <MDButton onClick={dismiss} color={'secondary'}>Anuluj</MDButton>
            {additionalActions}
            <MDButton onClick={send} color={actionColor}>{actionLabel}</MDButton>
        </DialogActions>
    </Dialog>);
}